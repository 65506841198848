/* adapted from squiz-2015-internet project
/*globals debounce, sqAnimations, navigator*/
/* we don't exclude small screens so mediaQueries is a missing variable here */
(function initAnimations(animations) {

    if (navigator.userAgent.toLowerCase().indexOf('firefox') === -1) {
        $('html').addClass('svg-transform-origin');
    }

    // Init animations for wider screens only
    if (!mediaQueries.wide.mql.matches) {
        // @todo - resize activation?
        return false;
    }

    var $w = $(window),
        $animations = $('.animation'),
        ticking = false,
        elemPositions = {};


    function getPositionForElem($elem) {
        var pos = {};
        pos.elem = $elem.get(0);
        pos.top = $elem.offset().top;
        pos.height = $elem.outerHeight();
        pos.bottom = pos.top + pos.height;
        return pos;
    }

    function triggerAnimationState($elem, isVisible, animation) {
        // Trigger visible state
        if (isVisible && !$elem.hasClass('is-visible')) {
            $elem.addClass('is-visible');
            if (animation) {
                animation['in']();
            }
        } else if (!isVisible && $elem.hasClass('is-visible')) {
            $elem.removeClass('is-visible');
            if (animation) {
                animation['out']();
            }
        }
    }

    // Check the position of an animated element against scroll position, viewport and
    // cached position data.
    function doPositionCheck() {
        var scrollTop = window.pageYOffset;
        var viewportHeight = window.innerHeight;
        var viewportBottom = scrollTop + viewportHeight;
        $.each(elemPositions, function eachElem(id, pos) {

            // we need to get the pos within this loop
            // because the height of inactive tabs at setup() isn't correct
            var $elem = $(pos.elem),
                // pos = getPositionForElem($elem),
                animation = animations[id];
            if (viewportHeight > 600) {
                // element isVisible when it is entirely in view
                isVisible = pos.bottom <= viewportBottom && scrollTop <= pos.top;
            } else {
                // element isVisible when it is half in view
                isVisible = (pos.bottom - pos.height / 2 <= viewportBottom) && (scrollTop - pos.height / 2 <= pos.top);
            }

            if ($elem.parent().hasClass('tabs__target') && !$elem.parent().hasClass('uber-accordion__target-active')) {
                isVisible = false;
            } else if ($elem.parent().hasClass('tabs__target') && $elem.parent().hasClass('uber-accordion__target-active')) {
                isVisible = true;
            }

            // Allow triggering the animation only once by preserving the visible state
            if ($elem.hasClass('animation--once') && $elem.hasClass('is-visible')) {
                return;
            }
            triggerAnimationState($elem, isVisible, animation);

        });
        ticking = false;
    }

    // Perform the position checking on the next tick only
    function nextTick() {
        if (!ticking) {
            window.requestAnimationFrame(function () {
                doPositionCheck();
            });
        }
        ticking = true;
    }

    function setup() {
        return debounce(function calculateElemPositions() {
            elemPositions = {};

            // Positions for .animation__on-scroll elements that don't necessarily
            // have triggered animations from JS
            $animations.each(function (i, elem) {
                var id = $(elem).attr('id') || 'animated-elem-' + i;
                if (!elemPositions.hasOwnProperty(id)) {
                    elemPositions[id] = getPositionForElem($(elem));
                }
            });

            doPositionCheck();
        }, 50);
    }

    // Cache element positions - updating on resize
    $w.on('load.anim', setup());
    $w.on('resize.anim', setup());
    $w.on('scroll.anim', nextTick);

    // When a tab link pointing to a tab with an animation is clicked we want to trigger an animation state
    // normally it's only done on scroll but if we're stationary we want to trigger the animation in the active tab
    $('.animation__tabs .tabs__link').click(function () {

        // update the height of the active tab once it's visible
        var targetId = $(this).attr('href').substring(1);
        elemPositions[targetId] = getPositionForElem($('#' + targetId));

        doPositionCheck();
    });

}(sqAnimations));