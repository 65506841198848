/*globals Snap, sqAnimations*/
(function autocomplete(exports) {
    var id = 'animation__autocomplete';
    if (!$('#' + id).length) {
        return;
    }
    var s = new Snap('#' + id + ' svg'),
        input = s.select('.text'),
        text = 'John',
        results = s.select('.results'),
        duration = 200,
        delay = 5000,
        interval;

    function typeWriter(text, n) {
        if (n < (text.length)) {
            var singleCharacter = text.substring(0, n + 1);
            input.attr({
                text: singleCharacter
            });
            n++;
            if ($('#' + id).hasClass('is-visible')) {
                setTimeout(function () {
                    typeWriter(text, n)
                }, duration);
            } else {
                return;
            }
        }
    }

    function doAnimation() {
        results.attr({
            opacity: 0,
            transform: "t0,5s"
        });
        typeWriter(text, 0);
        setTimeout(function () {
            results.animate({
                opacity: 1,
                transform: "t0,0s"
            }, duration);
        }, 3 * duration);
    }
    exports[id] = {
        $elem: $('#' + id),
        in: function () {
            doAnimation();
            interval = setInterval(doAnimation, delay);
        },
        out: function () {
            clearInterval(interval);
        }
    };
}(sqAnimations));
(function accessibilityAuditor(exports) {
    var id = 'animation__accessibility-auditor';
    if (!$('#' + id).length) {
        return;
    }
    var s = new Snap('#' + id + ' svg'),
        dots = s.selectAll('.points circle').items,
        path = s.select('.path'),
        polyline = s.select('.poly'),
        max = 315,
        min = 80,
        duration = 500,
        delay = 3000,
        textUrlsAffected = s.select('.urls-affected'),
        textDate = s.select('.date'),
        textUrlsChecked = s.select('.urls-checked'),
        textUrlsOccurences = s.select('.urls-occurences'),
        textUrlsAffectedPercent = s.select('.urls-affectedPercent'),
        interval;

    function animateRandomPosition() {
        function randomNumber() {
            return Math.random() * (max - min) + min;
        }
        var polylineCoordStart = JSON.parse("[" + $('#' + id + ' svg ' + '.poly').attr('points') + "]");
        var animTo = {
            y1: randomNumber(),
            y2: randomNumber(),
            y3: randomNumber(),
            y4: randomNumber(),
            polylineCoordStart: polylineCoordStart
        };
        return animTo;
    }

    function doAnimation() {
        var obj = animateRandomPosition();
        var i = 1;
        var points = dots.map(function (point) {
            var coordY = (obj['y' + i]);
            point.animate({
                cy: coordY
            }, duration);
            i++;
        });

        function numberWithCommas(number) {
            return number.toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace(".", ",");
        }
        var baseValue = ((max * 4) - (obj['y1'] + obj['y2'] + obj['y3'] + obj['y4'])) / 1.3,
            urlsAffected = baseValue * Math.random() * (1 - 0),
            date = Math.floor(Math.random() * (31 - 1) + 1, 2),
            urlsChecked = baseValue,
            urlsOccurences = urlsChecked * Math.random() * (100 - 60),
            urlsAffectedPercent = urlsAffected / baseValue * 100;
        textUrlsAffected.attr({
            text: numberWithCommas(urlsAffected)
        });
        textDate.attr({
            text: date
        });
        textUrlsChecked.attr({
            text: numberWithCommas(urlsChecked)
        });
        textUrlsOccurences.attr({
            text: numberWithCommas(urlsOccurences)
        });
        textUrlsAffectedPercent.attr({
            text: urlsAffectedPercent.toFixed(2)
        });
        var pathCoordEnd = 'M 10 ' + obj['y1'] + ' L 145 ' + obj['y2'] + ' L 255 ' + obj['y3'] + ' L 391 ' + obj['y4'] + ' L 391 322 L 10 322 Z';
        path.animate({
            d: pathCoordEnd
        }, duration);
        var polylineCoordEnd = [10, obj['y1'], 145.5, obj['y2'], 255.5, obj['y3'], 391, obj['y4']]
        Snap.animate(obj['polylineCoordStart'], polylineCoordEnd, function (val) {
            polyline.attr({
                points: val
            })
        }, duration);
    }
    exports[id] = {
        $elem: $('#' + id),
        in: function () {
            doAnimation();
            interval = setInterval(doAnimation, delay);
        },
        out: function () {
            clearInterval(interval);
        }
    };
}(sqAnimations));
(function contentClassifier(exports) {
    var id = 'animation__content-classifier';
    if (!$('#' + id).length) {
        return;
    }
    var s = new Snap('#' + id + ' svg'),
        input = s.select('.text'),
        text = 'fashion',
        results = s.select('.results'),
        related = s.select('.related'),
        duration = 200,
        delay = 5000,
        interval;

    function typeWriter(text, n) {
        if (n < (text.length)) {
            var singleCharacter = text.substring(0, n + 1);
            input.attr({
                text: singleCharacter
            });
            n++;
            if ($('#' + id).hasClass('is-visible')) {
                setTimeout(function () {
                    typeWriter(text, n)
                }, duration);
            } else {
                return;
            }
        }
    }

    function doAnimation() {
        results.attr({
            opacity: 0,
            transform: "t0,5s"
        });
        related.attr({
            opacity: 0
        });
        typeWriter(text, 0);
        setTimeout(function () {
            results.animate({
                opacity: 1,
                transform: "t0,0s"
            }, duration);
        }, 3 * duration);
        setTimeout(function () {
            related.animate({
                opacity: 1
            }, duration);
        }, 5 * duration);
    }
    exports[id] = {
        $elem: $('#' + id),
        in: function () {
            doAnimation();
            interval = setInterval(doAnimation, delay);
        },
        out: function () {
            clearInterval(interval);
        }
    };
}(sqAnimations));
(function curator(exports) {
    var id = 'animation__curator';
    if (!$('#' + id).length) {
        return;
    }
    var s = new Snap('#' + id + ' svg'),
        input = s.select('.text'),
        text = 'enrolment',
        results = s.select('.results'),
        related = s.select('.related'),
        duration = 200,
        delay = 5000,
        interval;

    function typeWriter(text, n) {
        if (n < (text.length)) {
            var singleCharacter = text.substring(0, n + 1);
            input.attr({
                text: singleCharacter
            });
            n++;
            if ($('#' + id).hasClass('is-visible')) {
                setTimeout(function () {
                    typeWriter(text, n)
                }, duration);
            } else {
                return;
            }
        }
    }

    function doAnimation() {
        results.attr({
            opacity: 0,
            transform: "t-10,0s"
        });
        related.attr({
            opacity: 0,
            transform: "t0,5s"
        });
        typeWriter(text, 0);
        setTimeout(function () {
            results.animate({
                opacity: 1,
                transform: "t0,0s"
            }, duration);
        }, 3 * duration);
        setTimeout(function () {
            related.animate({
                opacity: 1,
                transform: "t0,0s"
            }, duration);
        }, 5 * duration);
    }
    exports[id] = {
        $elem: $('#' + id),
        in: function () {
            doAnimation();
            interval = setInterval(doAnimation, delay);
        },
        out: function () {
            clearInterval(interval);
        }
    };
}(sqAnimations));
(function documentClassifier(exports) {
    var id = 'animation__document-classifier';
    if (!$('#' + id).length) {
        return;
    }
    var s = new Snap('#' + id + ' svg'),
        input = s.select('.text'),
        text = 'Marvel',
        results01 = s.select('.results_01'),
        results02 = s.select('.results_02'),
        duration = 200,
        delay = 5000,
        interval;

    function typeWriter(text, n) {
        if (n < (text.length)) {
            var singleCharacter = text.substring(0, n + 1);
            input.attr({
                text: singleCharacter
            });
            n++;
            if ($('#' + id).hasClass('is-visible')) {
                setTimeout(function () {
                    typeWriter(text, n)
                }, duration);
            } else {
                return;
            }
        }
    }

    function doAnimation() {
        results01.attr({
            opacity: 0,
            transform: "t0,5s"
        });
        results02.attr({
            opacity: 0,
            transform: "t0,5s"
        });
        typeWriter(text, 0);
        setTimeout(function () {
            results01.animate({
                opacity: 1,
                transform: "t0,0s"
            }, duration);
        }, 3 * duration);
        setTimeout(function () {
            results02.animate({
                opacity: 1,
                transform: "t0,0s"
            }, duration);
        }, 5 * duration);
    }
    exports[id] = {
        $elem: $('#' + id),
        in: function () {
            doAnimation();
            interval = setInterval(doAnimation, delay);
        },
        out: function () {
            clearInterval(interval);
        }
    };
}(sqAnimations));
(function duplicateDetection(exports) {
    var id = 'animation__duplicate-detection';
    if (!$('#' + id).length) {
        return;
    }
    var s = new Snap('#' + id + ' svg'),
        bars = s.selectAll('.bar').items,
        bar = s.select('.bar'),
        text = s.select('.text'),
        max = 310,
        min = 1,
        duration = 1000,
        delay = 2000,
        interval;

    function animateRandomPosition(bar) {
        var width = Math.floor(Math.random() * (max / 1.5), 2);
        var animTo = {
            width: width,
            text: width
        };
        bar.stop().animate(animTo, duration);
        return animTo;
    }

    function doAnimation() {
        bar.animate({
            width: animateRandomPosition(bar).width
        }, duration);
        text.attr({
            text: animateRandomPosition(bar).text
        });
    }
    exports[id] = {
        $elem: $('#' + id),
        in: function () {
            doAnimation();
            interval = setInterval(doAnimation, delay);
        },
        out: function () {
            clearInterval(interval);
        }
    };
}(sqAnimations));
(function facetedNavigation(exports) {
    var id = 'animation__faceted-navigation';
    if (!$('#' + id).length) {
        return;
    }
    var s = new Snap('#' + id + ' svg'),
        radio = s.selectAll('.radio').items,
        results = s.selectAll('.results').items,
        duration = 200,
        delay = 3000,
        interval;

    function doAnimation() {
        radio[1].animate({
            opacity: 0
        }, 2 / duration);
        radio[0].animate({
            opacity: 1
        }, duration);
        results[1].animate({
            opacity: 0
        }, duration);
        results[0].animate({
            opacity: 1
        }, duration);
        setTimeout(function () {
            radio[0].animate({
                opacity: 0
            }, 2 / duration);
            radio[2].animate({
                opacity: 1
            }, duration);
            results[0].animate({
                opacity: 0
            }, duration);
            results[2].animate({
                opacity: 1
            }, duration);
        }, delay);
        setTimeout(function () {
            radio[2].animate({
                opacity: 0
            }, 2 / duration);
            radio[1].animate({
                opacity: 1
            }, duration);
            results[2].animate({
                opacity: 0
            }, duration);
            results[1].animate({
                opacity: 1
            }, duration);
        }, 2 * delay);
    }
    exports[id] = {
        $elem: $('#' + id),
        in: function () {
            doAnimation();
            interval = setInterval(doAnimation, 3 * delay);
        },
        out: function () {
            clearInterval(interval);
        }
    };
}(sqAnimations));
(function fwServicesImplementation(exports) {
    var id = 'animation__fw-services_implementation';
    if (!$('#' + id).length) {
        return;
    }
    var s = new Snap('#' + id + ' svg'),
        bars = s.selectAll('.bars rect').items,
        colors = ['#D3D3D3', '#B2B2B2', '#7C7C7C', '#C61F09'],
        duration = 1000,
        delay = 2000,
        interval;

    function randomColor(bar) {
        var color = colors[Math.floor(Math.random() * colors.length)];
        var animTo = {
            fill: color
        };
        bar.stop().animate(animTo, duration);
        return animTo;
    }

    function doAnimation() {
        var points = bars.map(function (bar) {
            bar.animate({
                fill: randomColor(bar).fill
            }, duration);
        });
    }
    exports[id] = {
        $elem: $('#' + id),
        in: function () {
            doAnimation();
            interval = setInterval(doAnimation, delay);
        },
        out: function () {
            clearInterval(interval);
        }
    };
}(sqAnimations));
(function fwServicesUserInterface(exports) {
    var id = 'animation__fw-services_user-interface';
    if (!$('#' + id).length) {
        return;
    }
    var s = new Snap('#' + id + ' svg'),
        bars = s.selectAll('.bars g rect').items,
        maxWidthSmall = 38.6,
        maxWidthLarge = 84.7,
        min = 1,
        duration = 1000,
        delay = 2000,
        interval;

    function animateRandomPosition(bar) {
        var max = bar.hasClass('bar--small') ? maxWidthSmall : maxWidthLarge;
        var width = Math.random() * ((max) - min) + min;
        var animTo = {
            width: width
        };
        bar.stop().animate(animTo, duration);
        return animTo;
    }

    function doAnimation() {
        var points = bars.map(function (bar) {
            return {
                width: animateRandomPosition(bar).width
            };
        });
    }
    exports[id] = {
        $elem: $('#' + id),
        in: function () {
            doAnimation();
            interval = setInterval(doAnimation, delay);
        },
        out: function () {
            clearInterval(interval);
        }
    };
}(sqAnimations));
(function locationBasedSearch(exports) {
    var id = 'animation__location-based-search';
    if (!$('#' + id).length) {
        return;
    }
    var s = new Snap('#' + id + ' svg'),
        input = s.select('.text'),
        text = '3015',
        pin = s.select('.pin'),
        data = s.select('.data'),
        duration = 200,
        delay = 5000,
        interval;

    function typeWriter(text, n) {
        if (n < (text.length)) {
            var singleCharacter = text.substring(0, n + 1);
            input.attr({
                text: singleCharacter
            });
            n++;
            if ($('#' + id).hasClass('is-visible')) {
                setTimeout(function () {
                    typeWriter(text, n)
                }, duration);
            } else {
                return;
            }
        }
    }

    function doAnimation() {
        pin.attr({
            opacity: 0,
            transform: "t0,-40s"
        });
        data.attr({
            opacity: 0
        });
        typeWriter(text, 0);
        setTimeout(function () {
            pin.animate({
                opacity: 1,
                transform: "t0,0s"
            }, 2 * duration, mina.bounce);
        }, 3 * duration);
        setTimeout(function () {
            data.animate({
                opacity: 1
            }, duration);
        }, 6 * duration);
    }
    exports[id] = {
        $elem: $('#' + id),
        in: function () {
            doAnimation();
            interval = setInterval(doAnimation, delay);
        },
        out: function () {
            clearInterval(interval);
        }
    };
}(sqAnimations));
(function missingMetadata(exports) {
    var id = 'animation__missing-metadata';
    if (!$('#' + id).length) {
        return;
    }
    var s = new Snap('#' + id + ' svg'),
        bars = s.selectAll('.bar').items,
        bar = s.select('.bar'),
        textDates = s.select('.dates'),
        textAuthors = s.select('.authors'),
        maxWidth = 310,
        max = 99,
        min = 1,
        duration = 1000,
        delay = 2000,
        interval;

    function doAnimation() {
        var dates = Math.floor(Math.random() * ((max) - min) + min, 2);
        var authors = Math.floor(Math.random() * ((max) - min) + min, 2);
        var width = ((dates + authors) / (2 * max)) * maxWidth;
        textDates.attr({
            text: dates
        });
        textAuthors.attr({
            text: authors
        });
        bar.animate({
            width: width
        }, duration);
    }
    exports[id] = {
        $elem: $('#' + id),
        in: function () {
            doAnimation();
            interval = setInterval(doAnimation, delay);
        },
        out: function () {
            clearInterval(interval);
        }
    };
}(sqAnimations));
(function naturalLanguageProcessing(exports) {
    var id = 'animation__natural-language-processing';
    if (!$('#' + id).length) {
        return;
    }
    var s = new Snap('#' + id + ' svg'),
        input = s.select('.text'),
        text = 'Who is the Manager of',
        results = s.select('.results'),
        duration = 100,
        delay = 6000,
        interval;

    function typeWriter(text, n) {
        if (n < (text.length)) {
            var singleCharacter = text.substring(0, n + 1);
            input.attr({
                text: singleCharacter
            });
            n++;
            if ($('#' + id).hasClass('is-visible')) {
                setTimeout(function () {
                    typeWriter(text, n)
                }, duration);
            } else {
                return;
            }
        }
    }

    function doAnimation() {
        results.attr({
            opacity: 0,
            transform: "t0,5s"
        });
        typeWriter(text, 0);
        setTimeout(function () {
            results.animate({
                opacity: 1,
                transform: "t0,0s"
            }, 2 * duration);
        }, 7 * duration);
    }
    exports[id] = {
        $elem: $('#' + id),
        in: function () {
            doAnimation();
            interval = setInterval(doAnimation, delay);
        },
        out: function () {
            clearInterval(interval);
        }
    };
}(sqAnimations));
(function performance(exports) {
    var id = 'animation__performance';
    if (!$('#' + id).length) {
        return;
    }
    var s = new Snap('#' + id + ' svg'),
        bars = s.selectAll('.performance--arrow').items,
        max = 360,
        duration = 2000,
        delay = 2000,
        interval,
        coordX = [73.27, 331.13, 204.65],
        coordY = [232.89, 232.56, 194.28];

    function animateRandomPosition(bar, i) {
        var deg = Math.floor(Math.random() * max);
        var animTo = {
            transform: deg + ',' + coordX[i] + ',' + coordY[i]
        };
        bar.stop().animate(animTo, duration);
        return animTo;
    }

    function doAnimation() {
        for (var key in bars) {
            var bar = bars[key];
            bar.animate({
                transform: 'r' + animateRandomPosition(bar, key).transform
            }, duration);
        }
    }
    exports[id] = {
        $elem: $('#' + id),
        in: function () {
            doAnimation();
            interval = setInterval(doAnimation, delay);
        },
        out: function () {
            clearInterval(interval);
        }
    };
}(sqAnimations));
(function predictiveSegmentation(exports) {
    var id = 'animation__predictive-segmentation';
    if (!$('#' + id).length) {
        return;
    }
    var s = new Snap('#' + id + ' svg'),
        input = s.select('.text'),
        results = s.select('.results'),
        text = 'weather',
        duration = 200,
        delay = 5000,
        interval;

    function typeWriter(text, n) {
        if (n < (text.length)) {
            var singleCharacter = text.substring(0, n + 1);
            input.attr({
                text: singleCharacter
            });
            n++;
            if ($('#' + id).hasClass('is-visible')) {
                setTimeout(function () {
                    typeWriter(text, n)
                }, duration);
            } else {
                return;
            }
        }
    }

    function doAnimation() {
        results.attr({
            opacity: 0
        });
        typeWriter(text, 0);
        setTimeout(function () {
            results.animate({
                opacity: 1
            }, 2 * duration);
        }, 3 * duration);
    }
    exports[id] = {
        $elem: $('#' + id),
        in: function () {
            doAnimation();
            interval = setInterval(doAnimation, delay);
        },
        out: function () {
            clearInterval(interval);
        }
    };
}(sqAnimations));
(function queryCompletion(exports) {
    var id = 'animation__query-completion';
    if (!$('#' + id).length) {
        return;
    }
    var s = new Snap('#' + id + ' svg'),
        input = s.select('.text'),
        text = 'primary',
        results = s.select('.results'),
        duration = 200,
        delay = 5000,
        interval;

    function typeWriter(text, n) {
        if (n < (text.length)) {
            var singleCharacter = text.substring(0, n + 1);
            input.attr({
                text: singleCharacter
            });
            n++;
            if ($('#' + id).hasClass('is-visible')) {
                setTimeout(function () {
                    typeWriter(text, n)
                }, duration);
            } else {
                return;
            }
        }
    }

    function doAnimation() {
        results.attr({
            opacity: 0,
            transform: "t0,5s"
        });
        typeWriter(text, 0);
        setTimeout(function () {
            results.animate({
                opacity: 1,
                transform: "t0,0s"
            }, duration);
        }, 3 * duration);
    }
    exports[id] = {
        $elem: $('#' + id),
        in: function () {
            doAnimation();
            interval = setInterval(doAnimation, delay);
        },
        out: function () {
            clearInterval(interval);
        }
    };
}(sqAnimations));
(function readingLevel(exports) {
    var id = 'animation__reading-level';
    if (!$('#' + id).length) {
        return;
    }
    var s = new Snap('#' + id + ' svg'),
        bars = s.selectAll('.bars rect').items,
        max = 303,
        min = 10,
        duration = 1000,
        delay = 2000,
        interval;

    function animateRandomPosition(bar) {
        var height = Math.floor(Math.random() * (max / 1.5), 2);
        var animTo = {
            y: max - height + (min * 2),
            height: height
        };
        bar.stop().animate(animTo, duration);
        return animTo;
    }

    function doAnimation() {
        var points = bars.map(function (bar) {
            return {
                y: animateRandomPosition(bar).y,
                x: bar.getBBox().cx
            };
        });
    }
    exports[id] = {
        $elem: $('#' + id),
        in: function () {
            doAnimation();
            interval = setInterval(doAnimation, delay);
        },
        out: function () {
            clearInterval(interval);
        }
    };
}(sqAnimations));
(function searchAnalytics(exports) {
    var id = 'animation__search-analytics';
    if (!$('#' + id).length) {
        return;
    }
    var s = new Snap('#' + id + ' svg'),
        dots = s.selectAll('.points circle').items,
        path = s.select('.path'),
        polyline = s.select('.poly'),
        max = 315,
        min = 80,
        duration = 500,
        delay = 3000,
        queries = ['SHARE', 'SOCIAL', 'COOL', 'LIKE', 'DINOSAR', 'FOSSILS', 'GAMES', 'MAGIC', 'CAREER', 'SEARCH', 'REASULTS'],
        textQueries = s.select('.queries'),
        textTopNumQueries = s.select('.top-num-queries'),
        textNumQueries = s.select('.num-queries'),
        textClicks = s.select('.clicks'),
        textBetClicks = s.select('.bet-clicks'),
        interval;

    function animateRandomPosition() {
        function randomNumber() {
            return Math.floor(Math.random() * (max - min) + min, 2);
        }
        var polylineCoordStart = JSON.parse("[" + $('#' + id + ' svg ' + '.poly').attr('points') + "]");
        var animTo = {
            y1: randomNumber(),
            y2: randomNumber(),
            y3: randomNumber(),
            y4: randomNumber(),
            polylineCoordStart: polylineCoordStart
        };
        return animTo;
    }

    function doAnimation() {
        var obj = animateRandomPosition();
        var i = 1;
        var points = dots.map(function (point) {
            var coordY = (obj['y' + i]);
            point.animate({
                cy: coordY
            }, duration);
            i++;
        });

        function numberWithCommas(number) {
            return number.toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace(".", ",");
        }
        var baseValue = ((max * 4) - (obj['y1'] + obj['y2'] + obj['y3'] + obj['y4'])) / 3;
        var TopNumQueries = baseValue * Math.random() * (0.8 - 0.2);
        var numQueries = baseValue;
        var Clicks = baseValue * Math.random() * (2.5 - 1.5) + 1.5;
        var BetClicks = TopNumQueries * Math.random() / (5.5 - 1.5) + 1.5;
        textQueries.attr({
            text: queries[Math.floor(Math.random() * queries.length)]
        });
        textTopNumQueries.attr({
            text: numberWithCommas(TopNumQueries)
        });
        textNumQueries.attr({
            text: numberWithCommas(numQueries)
        });
        textClicks.attr({
            text: numberWithCommas(Clicks)
        });
        textBetClicks.attr({
            text: numberWithCommas(BetClicks)
        });
        var pathCoordEnd = 'M 10 ' + obj['y1'] + ' L 145 ' + obj['y2'] + ' L 255 ' + obj['y3'] + ' L 390 ' + obj['y4'] + ' L 390 324 L 10 324 Z';
        path.animate({
            d: pathCoordEnd
        }, duration);
        var polylineCoordEnd = [10, obj['y1'], 145.5, obj['y2'], 255.5, obj['y3'], 390, obj['y4']]
        Snap.animate(obj['polylineCoordStart'], polylineCoordEnd, function (val) {
            polyline.attr({
                points: val
            })
        }, duration);
    }
    exports[id] = {
        $elem: $('#' + id),
        in: function () {
            doAnimation();
            interval = setInterval(doAnimation, delay);
        },
        out: function () {
            clearInterval(interval);
        }
    };
}(sqAnimations));
(function shortlisting(exports) {
    var id = 'animation__shortlisting';
    if (!$('#' + id).length) {
        return;
    }
    var s = new Snap('#' + id + ' svg'),
        input = s.select('.text'),
        text = 'economics',
        results = s.select('.results'),
        duration = 200,
        delay = 5000,
        interval;

    function typeWriter(text, n) {
        if (n < (text.length)) {
            var singleCharacter = text.substring(0, n + 1);
            input.attr({
                text: singleCharacter
            });
            n++;
            if ($('#' + id).hasClass('is-visible')) {
                setTimeout(function () {
                    typeWriter(text, n)
                }, duration);
            } else {
                return;
            }
        }
    }

    function doAnimation() {
        results.attr({
            opacity: 0,
            transform: "t0,5s"
        });
        typeWriter(text, 0);
        setTimeout(function () {
            results.animate({
                opacity: 1,
                transform: "t0,0s"
            }, duration);
        }, 3 * duration);
    }
    exports[id] = {
        $elem: $('#' + id),
        in: function () {
            doAnimation();
            interval = setInterval(doAnimation, delay);
        },
        out: function () {
            clearInterval(interval);
        }
    };
}(sqAnimations));
(function seoAuditor(exports) {
    var id = 'animation__seo-auditor';
    if (!$('#' + id).length) {
        return;
    }
    var s = new Snap('#' + id + ' svg'),
        max = 1000,
        min = 100,
        duration = 500,
        delay = 3000,
        movies = ['Hancock', 'Star Wars', 'Inception', 'The Matrix', 'Interstellar', 'Alien', 'The Prestige', 'Rogue One', 'Jurassic Park', 'Akira', 'The Martian'],
        textMovies = s.select('.movies'),
        textMoviesUrl = s.select('.movies-url'),
        textRank = s.select('.rank'),
        textTotalWords = s.select('.total-words'),
        textUniqueWords = s.select('.unique-words'),
        textMatching = s.select('.matching'),
        interval;

    function doAnimation() {
        var num = Math.floor(Math.random() * (max - min) + min, 2);

        function numberWithCommas(number) {
            return number.toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace(".", ",");
        }
        var movie = movies[Math.floor(Math.random() * movies.length)];
        var movieUrl = movie.toLowerCase().replace(/[^a-z0-9-]/g, '-');
        var rank = Math.floor(Math.random() * (9 - 4) + 4, 2);
        var totalWords = num;
        var uniqueWords = Math.floor(totalWords / (Math.random() * (4.5 - 1.5) + 1.5), 2);
        var maching = Math.floor(uniqueWords / rank, 2);
        textMovies.attr({
            text: movie
        });
        textMoviesUrl.attr({
            text: movieUrl
        });
        textRank.attr({
            text: rank
        });
        textTotalWords.attr({
            text: totalWords
        });
        textUniqueWords.attr({
            text: uniqueWords
        });
        textMatching.attr({
            text: maching
        });
    }
    exports[id] = {
        $elem: $('#' + id),
        in: function () {
            doAnimation();
            interval = setInterval(doAnimation, delay);
        },
        out: function () {
            clearInterval(interval);
        }
    };
}(sqAnimations));
(function synonyms(exports) {
    var id = 'animation__synonyms';
    if (!$('#' + id).length) {
        return;
    }
    var s = new Snap('#' + id + ' svg'),
        input = s.selectAll('.text').items,
        text = ['automobile', 'motor car', 'auto'],
        duration = 100,
        delay = 6000,
        interval;

    function typeWriter(text, i, n) {
        if (n < (text.length)) {
            if ($('#' + id).hasClass('is-visible')) {
                var singleCharacter = text.substring(0, n + 1);
                input[i].attr({
                    text: singleCharacter
                });
                n++;
                setTimeout(function () {
                    typeWriter(text, i, n)
                }, duration);
            } else {
                return;
            }
        }
    }

    function doAnimation() {
        input[1].attr({
            text: ''
        });
        input[2].attr({
            text: ''
        });
        typeWriter(text[0], 0, 0);
        setTimeout(function () {
            typeWriter(text[1], 1, 0);
        }, delay / 3);
        setTimeout(function () {
            typeWriter(text[2], 2, 0);
        }, delay / 1.5);
    }
    exports[id] = {
        $elem: $('#' + id),
        in: function () {
            doAnimation();
            interval = setInterval(doAnimation, delay);
        },
        out: function () {
            clearInterval(interval);
        }
    };
}(sqAnimations));
(function textMiner(exports) {
    var id = 'animation__text-miner';
    if (!$('#' + id).length) {
        return;
    }
    var s = new Snap('#' + id + ' svg'),
        input = s.select('.text'),
        text = 'honour',
        results = s.select('.results'),
        help = s.select('.help'),
        duration = 200,
        delay = 5000,
        interval;

    function typeWriter(text, n) {
        if (n < (text.length)) {
            var singleCharacter = text.substring(0, n + 1);
            input.attr({
                text: singleCharacter
            });
            n++;
            if ($('#' + id).hasClass('is-visible')) {
                setTimeout(function () {
                    typeWriter(text, n)
                }, duration);
            } else {
                return;
            }
        }
    }

    function doAnimation() {
        results.attr({
            opacity: 0,
            transform: "t0,5s"
        });
        help.attr({
            opacity: 0
        });
        typeWriter(text, 0);
        setTimeout(function () {
            results.animate({
                opacity: 1,
                transform: "t0,0s"
            }, duration);
        }, 3 * duration);
        setTimeout(function () {
            help.animate({
                opacity: 1
            }, duration);
        }, 6 * duration);
    }
    exports[id] = {
        $elem: $('#' + id),
        in: function () {
            doAnimation();
            interval = setInterval(doAnimation, delay);
        },
        out: function () {
            clearInterval(interval);
        }
    };
}(sqAnimations));
(function trendAlerts(exports) {
    var id = 'animation__trend-alerts';
    if (!$('#' + id).length) {
        return;
    }
    var s = new Snap('#' + id + ' svg'),
        rows = s.selectAll('.rows .row').items,
        zoomRow = s.select('.row--zoom'),
        zoomMin = Number(zoomRow.attr('data-min')),
        day = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'],
        month = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
        duration = 500,
        delay = 3000,
        interval;

    function coords(row) {
        var min = Number(row.attr('data-min'));
        var max = Number(row.attr('data-max'));

        function randomNumber() {
            return Math.floor(Math.random() * (max - min) + min, 2);
        }
        var coords = {
            y0: randomNumber(),
            y1: randomNumber(),
            y2: randomNumber(),
            y3: randomNumber()
        };
        return coords;
    }

    function zoomCoords(yCoord, baseMin) {
        var coords = {
            y0: Math.floor(zoomMin * (yCoord['y0'] / baseMin), 2),
            y1: Math.floor(zoomMin * (yCoord['y1'] / baseMin), 2),
            y2: Math.floor(zoomMin * (yCoord['y2'] / baseMin), 2),
            y3: Math.floor(zoomMin * (yCoord['y3'] / baseMin), 2)
        }
        return coords;
    }

    function polyAnimation(polyline, polylineCoordStart, polylineCoordEnd) {
        Snap.animate(polylineCoordStart, polylineCoordEnd, function (val) {
            polyline.attr({
                points: val
            });
        }, duration);
    };

    function dotsAnimation(dots, yCoord) {
        for (var dot in dots) {
            dots[dot].animate({
                cy: yCoord['y' + dot]
            }, duration);
        };
    };

    function doAnimation() {
        for (var row in rows) {
            var yCoord = coords(rows[row]);
            var dots = rows[row].selectAll('.dots circle').items;
            var text = rows[row].select('.text');
            var polyline = rows[row].select('.graph polyline');
            var polylineCoordStart = JSON.parse("[" + polyline.attr('points') + "]");
            var polylineCoordEnd = [155, yCoord['y0'], 180, yCoord['y1'], 205, yCoord['y2'], 230, yCoord['y3']];
            dotsAnimation(dots, yCoord);
            polyAnimation(polyline, polylineCoordStart, polylineCoordEnd);
            text.attr({
                text: Math.floor(1000 / (yCoord['y0'] + yCoord['y1'] + yCoord['y2'] + yCoord['y3']) * 100, 2) + '%'
            });
            if (rows[row].hasClass('row--to-zoom')) {
                var zoomText = zoomRow.select('.text')
                var zoomDate = day[Math.floor(Math.random() * day.length)] + ' ' + Math.floor(Math.random() * (28 - 1) + 1, 2) + ' ' + month[Math.floor(Math.random() * month.length)];
                var baseMin = Number(rows[row].attr('data-min'));
                var zoomYCoord = zoomCoords(yCoord, baseMin);
                var zoomDots = zoomRow.selectAll('.dots circle').items;
                var zoomPath = zoomRow.select('.graph path');
                var zoomPathCoordEnd = 'M 180 ' + zoomYCoord['y0'] + ' L 246 ' + zoomYCoord['y1'] + ' L 313 ' + zoomYCoord['y2'] + ' L 380 ' + zoomYCoord['y3'] + ' L 380 260 L 180 260 Z';
                var zoomPolyline = zoomRow.select('.graph polyline');
                var zoomPolylineCoordStart = JSON.parse("[" + zoomPolyline.attr('points') + "]");
                var zoomPolylineCoordEnd = [180, zoomYCoord['y0'], 246, zoomYCoord['y1'], 313, zoomYCoord['y2'], 380, zoomYCoord['y3']];
                zoomText.attr({
                    text: zoomDate
                });
                dotsAnimation(zoomDots, zoomYCoord);
                polyAnimation(zoomPolyline, zoomPolylineCoordStart, zoomPolylineCoordEnd);
                zoomPath.animate({
                    d: zoomPathCoordEnd
                }, duration);
            }
        }
    }
    exports[id] = {
        $elem: $('#' + id),
        in: function () {
            doAnimation();
            interval = setInterval(doAnimation, delay);
        },
        out: function () {
            clearInterval(interval);
        }
    };
}(sqAnimations));